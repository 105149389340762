exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-layouts-blog-js": () => import("./../../../src/layouts/blog.js" /* webpackChunkName: "component---src-layouts-blog-js" */),
  "component---src-layouts-definition-js": () => import("./../../../src/layouts/definition.js" /* webpackChunkName: "component---src-layouts-definition-js" */),
  "component---src-layouts-glossary-js": () => import("./../../../src/layouts/glossary.js" /* webpackChunkName: "component---src-layouts-glossary-js" */),
  "component---src-layouts-policy-js": () => import("./../../../src/layouts/policy.js" /* webpackChunkName: "component---src-layouts-policy-js" */),
  "component---src-layouts-post-js": () => import("./../../../src/layouts/post.js" /* webpackChunkName: "component---src-layouts-post-js" */),
  "component---src-layouts-term-js": () => import("./../../../src/layouts/term.js" /* webpackChunkName: "component---src-layouts-term-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-product-feedback-js": () => import("./../../../src/pages/product/feedback.js" /* webpackChunkName: "component---src-pages-product-feedback-js" */),
  "component---src-pages-product-ideas-js": () => import("./../../../src/pages/product/ideas.js" /* webpackChunkName: "component---src-pages-product-ideas-js" */),
  "component---src-pages-product-roadmaps-js": () => import("./../../../src/pages/product/roadmaps.js" /* webpackChunkName: "component---src-pages-product-roadmaps-js" */),
  "component---src-pages-product-testimonial-js": () => import("./../../../src/pages/product/testimonial.js" /* webpackChunkName: "component---src-pages-product-testimonial-js" */)
}

